* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #f9f9f9;
}

.content {
  width: 100%;
  max-width: 600px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.centered-image {
  height: 200px;
  width: 200px;
  margin: 0 auto;
}

h3 {
  font-size: 1.5rem;
  color: #333;
}

.description {
  font-size: 1.1rem;
  color: #555;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.letter-body {
  font-size: 1rem;
  color: #666;
  text-align: left;
  line-height: 1.6;
  margin-bottom: 1rem;
}

.recipients {
  font-size: 0.9rem;
  color: #333;
  margin-top: 1rem;
}

.send-button {
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
}

.send-button:hover {
  background-color: #0056b3;
}

.visitor-counter {
  margin-top: 1.5rem;
  font-size: 0.9rem;
  color: #888;
}

@media (max-width: 768px) {
  .content {
    padding: 15px;
  }

  .centered-image {
    height: 200px;
    width: 200px;
  }

  h3 {
    font-size: 1.3rem;
  }

  .description {
    font-size: 1rem;
  }

  .letter-body {
    font-size: 0.9rem;
  }

  .send-button {
    padding: 0.7rem 1.2rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .content {
    padding: 10px;
  }

  .centered-image {
    height: 200px;
    width: 200px;
  }

  h3 {
    font-size: 1.2rem;
  }

  .description {
    font-size: 0.9rem;
  }

  .letter-body {
    font-size: 0.8rem;
  }

  .send-button {
    padding: 0.6rem 1rem;
    font-size: 0.8rem;
  }
}
